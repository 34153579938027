@import "./scss/global/shared.scss";
/* 404 NO_ROUTE
-------------------------------------------------------------- */

.cms-index-noroute {
    .content-main {
        padding-bottom: 0;
    }

    .footer-bok {
        display: none;
    }

    .header-main {
        @media #{$media-xs} {
            margin-bottom: 0;
        }
    }
}

.page-404 {
    picture {
        img {
            width: 100%;
            max-height: 420px;

            @media #{$media-md} {
                max-height: 400px;
            }
            @media #{$media-sm} {
                max-height: 320px;
            }

            @media only screen and (min-width: 480px) and (max-width: 768px) {
                max-height: 290px;
            }

            @media #{$media-xs-l} {
                max-height: 238px;
            }
        }
    }

    .banner-404 {
        padding-top: 40px;
        padding-bottom: 40px;

        @media #{$media-xs} {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}
